
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class MainBanner extends Vue {
  @Prop() private bannerHeading!: string;
  @Prop() private bannerHeadingEmphasis!: string;
  @Prop() private bannerText!: string;
  @Prop() private bannerText1!: string;
  @Prop() private bannerImage!: string;
  @Prop() private bannerImageAlt!: string;
  @Prop() private bannerAction!: object;

  public headerScroll() {
    const target = document.querySelector(".titletext");
    if (target !== null) {
      const targetPos = target.getBoundingClientRect().top;
      const startPos = window.pageYOffset;
      const distance = targetPos - startPos;
      let startTime: number | null = null;
      const duration = 500;

      const ease = (
        timeElapsed: number,
        startPosition: number,
        distance: number,
        duration: number
      ) => {
        timeElapsed /= duration / 2;
        if (timeElapsed < 1)
          return (distance / 2) * timeElapsed * timeElapsed + startPosition;
        timeElapsed--;
        return (
          (-distance / 2) * (timeElapsed * (timeElapsed - 2) - 1) +
          startPosition
        );
      };

      const animation = (currentTime: number) => {
        let timeElapsed = 0;
        if (startTime === null) {
          startTime = currentTime;
        } else {
          timeElapsed = currentTime - startTime;
        }
        const run = ease(timeElapsed, startPos, distance, duration);
        window.scrollTo(0, run);
        if (timeElapsed !== null && timeElapsed < duration) {
          requestAnimationFrame(animation);
        }
      };

      requestAnimationFrame(animation);
    } else return;
  }
}
