
import { Component, Vue } from "vue-property-decorator";
import LayoutDefault from "../layouts/LayoutDefault.vue";
import MainBanner from "@/components/MainBanner.vue";
import TitleText from "@/components/TitleText.vue";
import CardShowcase from "@/components/CardShowcase.vue";
import Card from "@/components/common-ui/Card.vue";

@Component<any>({
  metaInfo() {
      return {
        title: "Leighton Products - Tech, Talent, Together",
        meta: [{
          vmid: 'description',
          name: 'description',
          content: `${ this.banner.text }`,
        }]
    }
  },
  components: {
    LayoutDefault,
    MainBanner,
    TitleText,
    CardShowcase,
    Card
  }
})
export default class Solutions extends Vue {
  private banner = {
    heading: "We practice what we",
    emphasis: "preach",
    text: "We use our own methodology to develop our own business ideas too.",
    action: {
      text: "Learn More"
    },
    image: require("../assets/banner-images/products.png")
  };

  private titleText1 = {
    customClass: "mt-md",
    title: `
      What are we currently working on?
    `,
    subTitle: `
      That’s right – we love what we do, so we just have to do more of it. That is why we are currently launching a new product, <a href="https://www.wearehuman.hr/" target="_blank">human by leighton</a>.
      <br><br>
      human is a customisable employee onboarding platform that supports the HR function by saving time, improving governance and creating a faster more engaging induction process.
      <br><br>
      Not only are we helping to give all new employees the perfect start in their new role, but we are learning the latest and greatest technologies as we go. From AWS and AI to Security and Video Conferencing we are pushing the boundaries to make the improbable, possible.  And each step of the way we are refining our own delivery methodology, 
      <a href="/about#path">
      The Path
      </a>.
      <br><br>
      Click below if you want to find out more about how human is revolutionising the Employee Experience. 
    `,
    buttonLink: "https://wearehuman.hr",
    buttonLinkExternal: true,
    buttonText: "Learn more",
    buttonLabel: "Visit the human onboarding website",
    decorator: [
      {
        image: require("../assets/page-decorators/green-pill.png"),
        type: "green-pill",
        top: "100px",
        left: "150px"
      },
      {
        image: require("../assets/page-decorators/bubble5-small.png"),
        type: "bubble-one",
        top: "-100px",
        right: "1150px"
      }
    ]
  };

  private titleText2 = {
    customClass: "background--grey mt-md py-md",
    title: `
      Coming down the line
    `,
    subTitle: `
      A brand new concept that combines our love of exceptional software solutions with our passion for nurturing people (to be the best version of themselves) Academy by Leighton will make would be coders, job ready Software Engineers in just 14 weeks.<br><br>
      <img src="${require("@/assets/academy.png")}" alt='Academy by Leighton'
      <br><br><br>
      Our approach will focus on teaching the most in demand software languages in the industry combined with our own trusted methodology to develop commercially ready Software Engineers.<br><br>
      Click here if you’d like to learn more about Academy by Leighton
    `,
    buttonText: "Learn more",
    buttonLink: "https://leighton.academy/",
    buttonLinkExternal: true,
    buttonLabel: "Visit the Academy by Leighton website",
    decorator: [
      {
        image: require("../assets/page-decorators/bubble5.png"),
        type: "bubble-one",
        top: "-125px",
        right: "1200px"
      }
    ]
  };

  private titleText3 = {
    customClass: "mt-md",
    title: `
      Been there, done that
    `,
    subTitle: `
      We practice what we preach, we use The Path for our own business ideas too and have helped create innovative companies that now operate on a global scale:-  
    `,
    decorator: [
      {
        image: require("../assets/page-decorators/purple-pill.png"),
        type: "purple-pill d-none d-xl-block",
        top: "450px",
        right: "-1950px"
      },
      {
        image: require("../assets/page-decorators/bubble3-medium.png"),
        type: "bubble-one",
        top: "1150px",
        right: "1200px"
      }
    ]
  };

  private cards = {
    customClass: "partner-cards my-md",
    icons: true,
    showcaseTypeDesktop: "static",
    showcaseTypeMobile: "single-scroller",
    values: [
      {
        icon: require("@/assets/partners-images/human.png"),
        text:
          "human is a customisable employee onboarding platform that supports the HR function by saving time, improving governance and creating a faster more engaging induction process."
      },
      {
        icon: require("@/assets/partners-images/salecycle.png"),
        text:
          "SaleCycle’s data driven features help you to increase conversions, recover sales, drive loyalty and understand your customers."
      },
      {
        icon: require("@/assets/partners-images/communicator.png"),
        text:
          "Communicator is a trusted partner in sophisticated email and SMS marketing, with a focus on helping ambitious brands achieve breakthrough results."
      },
      {
        icon: require("@/assets/partners-images/footycom.png"),
        text:
          "Footy.com is the home of football price comparison enabling you to compare prices from 100+ retailers."
      },
      {
        icon: require("@/assets/partners-images/workcast.png"),
        text:
          "Workcast are the leading Webinar, Webcast and Virtual Event provider who have helped to grow business with online events since 2008."
      },
      {
        icon: require("@/assets/partners-images/4projects.png"),
        text:
          "4Projects became the UK’s largest cloud based project management extranet for the construction sector prior to its acquisition by Viewpoint."
      },
      {
        icon: require("@/assets/partners-images/domainnames.png"),
        text:
          "DomainNames.com was Europe’s biggest domain name registrar with the world’s first automated domain name registration process prior to its acquisition by Verisign."
      }
    ]
  };
}
